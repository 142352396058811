:root {
  --main: #ffffff;
  --secondary: #000000;
}

.App {
  text-align: center;
  background-color: var(--main);
  font-family: 'Open Sans', sans-serif;
}

.top_border {
  height: 160px;
  width: 100%;
  background-color: var(--main);
}

.Bottom_border {
  width: 100%;
  overflow-x: hidden;
  background-color: var(--secondary);
}

.bottom_border_logo {
  width: 100%;
}

.Component {
  height: 90vh;
  width: 100%;
}

.Component_grid {
  height: 100%;
}

.Component_text_box {
  height: 100%;
}

.Component_text_white {
  color: var(--main);
}

.Component_text_black {
  color: var(--secondary);
}

.circle {
  z-index: 10000;
  width: 120px;
  height: 120px;
  line-height: 500px;
  border-radius: 50%;
  color: var(--main);
  text-align: center;
  background: var(--secondary);
  position: fixed;
  top: 1em;
  right: 1em;
}

.Box_in_circle {
  height: 100%;
}

.nav_item_in_circle {
  cursor: pointer;
}
